
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { FUTURES_MODES, placeOrder } from 'Store/v2/Futures';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Shift from 'UI/Shift.vue';
import Button from 'Control/Button.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import { SET_POSITION, SET_STOP_LOSS_ORDER, SET_TAKE_PROFIT_ORDER } from 'Store/v2/TakeProfitStopLossModal';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import FuturesOrderPresenter from 'Entities/privatePresenter/FuturesOrderPresenter';
import FuturesPosition from 'Entities/privatePresenter/FuturesPosition';
import TradingApi from 'Apis/Trading';
import FuturesOrdersRequest from 'Entities/privatePresenter/FuturesOrdersRequest';
import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';
import FuturesApi from 'Apis/Futures';
import GetPositionModeRequest from 'Entities/futuresOrderRegistrar/GetPositionModeRequest';

interface Data {
    timeoutId: null | ReturnType<typeof setTimeout>;
    isButtonDisabled: boolean;
    showTooltip: boolean;
    stringToFixedDigits: any;
    theme: any;
    activeTpOrder: FuturesOrderPresenter | undefined;
    activeSlOrder: FuturesOrderPresenter | undefined;
    pricePrecision: number;
    quantityPrecision: number;
}

interface Methods {
    startClosingProcess: () => void;
    closePosition: () => void;
    clearTimeoutId: () => void;
    setShowTooltip: () => void;
    openModal: () => void;
    getActiveTpOrder: () => void;
    getActiveSlOrder: () => void;
    getPrecisions: () => void;
}

interface Computed {
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    updatedTpOrder: FuturesOrderPresenter | undefined;
    updatedSlOrder: FuturesOrderPresenter | undefined;
    baseAssetSymbol: string;
    quoteAssetSymbol: string;
    isCoinMarginPosition: boolean;
    activeAccountId: string;
    quoteQuantity: string;
    margin: string;
}

interface Props {
    position: FuturesPosition;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        Shift,
        AssetValue,
        Button,
    },
    props: {
        position: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            timeoutId: null,
            isButtonDisabled: false,
            showTooltip: false,
            stringToFixedDigits,
            theme,
            activeTpOrder: undefined,
            activeSlOrder: undefined,
            pricePrecision: 4,
            quantityPrecision: 8,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            currentPlacementId: 'Placements/activeTerminalPlacementId',
            activePlacementName: 'Placements/activeTerminalPlacementName',
        }),
        quoteQuantity() {
            if (this.isCoinMarginPosition) {
                return this.position.quoteQuantity.floor(8).noExponents().getSeparatedDigits();
            }
            return stringToFixedDigits(this.position.quoteQuantity.noExponents(), this.pricePrecision).getSeparatedDigits();
        },
        margin() {
            if (this.isCoinMarginPosition) {
                return this.position.margin.floor(8).noExponents().getSeparatedDigits();
            }
            return stringToFixedDigits(this.position.margin.noExponents(), this.pricePrecision).getSeparatedDigits();
        },
        baseAssetSymbol() {
            const symbol = this.position.contractSymbol;
            if (this.isCoinMarginPosition) {
                if (this.position.contractPrice) {
                    return 'Contract';
                }
                return symbol.split('/')[1];
            }
            if (this.position.quantityUnit.toLowerCase() === 'base') {
                return symbol.split('/')[0];
            }
            return 'Contract';
        },
        quoteAssetSymbol() {
            const symbol = this.position.contractSymbol;
            if (this.isCoinMarginPosition) {
                return symbol.split('/')[0];
            }
            return symbol.split('/')[1];
        },
        isCoinMarginPosition() {
            return this.position.contractSymbol.split('/')[1] === 'USD';
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        updatedTpOrder() {
            const tpOrders = this.$store.state.Futures.newTpSlOrders
                .filter((order: FuturesOrderPresenter) => (
                    order.placementName === this.position.placementName
                    && order.orderType === 'TAKE_PROFIT'
                    && order.accountId === this.position.accountId
                    && order.contractSymbol === this.position.contractSymbol
                    && (order.positionSide === this.position.side || (order.positionSide as any) === 'BOTH' || !order.positionSide)
                ));
            tpOrders.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).valueOf() - new Date(a).valueOf());
            const tpOrder = tpOrders.length ? tpOrders[0] : undefined;

            if (
                tpOrder
                && new Date(tpOrder.createdAt).valueOf() >= new Date(this.activeTpOrder?.createdAt ?? 0).valueOf()
            ) {
                if (
                    tpOrder.status === 'REGISTERED'
                    || tpOrder.status === 'PLACED'
                    || tpOrder.status === 'PARTIALY_FILLED'
                ) {
                    return tpOrder;
                }
                return undefined;
            }
            return this.activeTpOrder;
        },
        updatedSlOrder() {
            const slOrders = this.$store.state.Futures.newTpSlOrders.filter((order: FuturesOrderPresenter) => (
                order.placementName === this.position.placementName
                && order.orderType === 'STOP_LOSS'
                && order.accountId === this.position.accountId
                && order.contractSymbol === this.position.contractSymbol
                && (order.positionSide === this.position.side || (order.positionSide as any) === 'BOTH' || !order.positionSide)
            ));
            slOrders.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).valueOf() - new Date(a).valueOf());
            const slOrder = slOrders.length ? slOrders[0] : undefined;

            if (
                slOrder
                && new Date(slOrder.createdAt).valueOf() >= new Date(this.activeSlOrder?.createdAt ?? 0).valueOf()
            ) {
                if (
                    slOrder.status === 'REGISTERED'
                    || slOrder.status === 'PLACED'
                    || slOrder.status === 'PARTIALY_FILLED'
                ) {
                    return slOrder;
                }
                return undefined;
            }
            return this.activeSlOrder;
        },
    },
    methods: {
        async getActiveTpOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeTpOrder = undefined;
                    return;
                }

                const activePosition: FuturesPosition = this.position;
                const { data: mode } = await FuturesApi.privateFuturesGetPositionMode(new GetPositionModeRequest({
                    accountId: this.activeAccountId,
                    contractSymbol: activePosition.contractSymbol,
                    placementTag: this.$store.state.Placements.placementNamesToPlacementTags
                        .get(activePosition.placementName.toUpperCase()) ?? '',
                }));

                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: activePosition.placementName,
                    positionSide: mode.mode === 'ONE_WAY' ? undefined : activePosition.side as 'SHORT' | 'LONG',
                    spotAssetPairSymbol: activePosition.contractSymbol,
                    accountId: activePosition.accountId,
                    type: 'TAKE_PROFIT',
                }));

                if (orders.length > 0) {
                    [this.activeTpOrder] = orders;
                } else {
                    this.activeTpOrder = undefined;
                }
            } catch (error) {
                console.log('err', error);
                this.activeTpOrder = undefined;
            }
        },
        async getActiveSlOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeSlOrder = undefined;
                    return;
                }

                const activePosition: FuturesPosition = this.position;
                const { data: mode } = await FuturesApi.privateFuturesGetPositionMode(new GetPositionModeRequest({
                    accountId: this.activeAccountId,
                    contractSymbol: activePosition.contractSymbol,
                    placementTag: this.$store.state.Placements.placementNamesToPlacementTags
                        .get(activePosition.placementName.toUpperCase()) ?? '',
                }));

                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: activePosition.placementName,
                    positionSide: mode.mode === 'ONE_WAY' ? undefined : activePosition.side as 'SHORT' | 'LONG',
                    spotAssetPairSymbol: activePosition.contractSymbol,
                    accountId: activePosition.accountId,
                    type: 'STOP_LOSS',
                }));
                if (orders.length > 0) {
                    [this.activeSlOrder] = orders;
                } else {
                    this.activeSlOrder = undefined;
                }
            } catch (error) {
                console.log('err', error);
                this.activeSlOrder = undefined;
            }
        },
        startClosingProcess() {
            this.timeoutId = setTimeout(() => {
                this.closePosition();
            }, 1500);
        },
        async closePosition() {
            try {
                const positionPlacementTag = this.$store.state.Placements.placementNamesToPlacementTags.get(this.position.placementName.toUpperCase()) ?? '';
                const { data: mode } = await FuturesApi.privateFuturesGetPositionMode(new GetPositionModeRequest({
                    accountId: this.activeAccountId,
                    contractSymbol: this.position.contractSymbol,
                    placementTag: positionPlacementTag,
                }));

                this.isButtonDisabled = true;
                await this.$store.dispatch(placeOrder({
                    accountId: this.$store.getters['Accounts/activeAccountID'],
                    contractSymbol: this.position.contractSymbol,
                    leverage: Number(this.position.leverage),
                    placementName: this.position.placementName,
                    positionSide: mode.mode === FUTURES_MODES.oneWay ? 'BOTH' : this.position.side,
                    quantity: String(this.position.quantity),
                    // eslint-disable-next-line no-nested-ternary
                    side: mode.mode === FUTURES_MODES.oneWay
                        ? this.position.side.toLowerCase() === 'long'
                            ? 'SELL'
                            : 'BUY'
                        : 'SELL',
                    type: 'MARKET',
                }));
            } finally {
                this.isButtonDisabled = false;
            }
        },
        clearTimeoutId() {
            if (!this.timeoutId) {
                return;
            }
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        },
        async openModal() {
            this.$store.commit(SET_POSITION(this.position));
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                if (this.updatedTpOrder) {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(this.updatedTpOrder));
                } else {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(null));
                }
                if (this.updatedSlOrder) {
                    this.$store.commit(SET_STOP_LOSS_ORDER(this.updatedSlOrder));
                } else {
                    this.$store.commit(SET_STOP_LOSS_ORDER(null));
                }
                this.$modal.show('TakeProfitStopLossModal');
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        setShowTooltip() {
            this.showTooltip = true;
        },
        async getPrecisions() {
            try {
                const { data: assetPair } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                    placementName: this.position?.placementName ?? '',
                    symbol: this.position?.contractSymbol ?? '',
                }));
                this.quantityPrecision = assetPair.placementPrecisionQuantity ?? 8;
                this.pricePrecision = assetPair.placementPrecisionPrice ?? 4;
            } catch {
                this.quantityPrecision = 8;
                this.pricePrecision = 4;
            }
        },
    },
    created() {
        window.addEventListener('mouseup', () => {
            this.clearTimeoutId();
        });
        window.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (!path.some(({ classList }) => classList && Array.from(classList).includes('hold-button'))) {
                this.showTooltip = false;
            }
        });
    },
    async mounted() {
        await this.getActiveTpOrder();
        await this.getActiveSlOrder();
        await this.getPrecisions();
    },
});
