
import mixins from 'vue-typed-mixins';
import { maxValue, minValue, sameAs } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import WithdrawData from 'Modules/Payment/components/Withdraw/Withdraw.Data.vue';
import { hasManifest, getManifest } from 'Models/assetsManifest';
import { PlacementTypes } from 'Models/placements';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import { Account } from 'Models/accounts';
import numberFormater from 'Mixins/numberFormater';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Balance from 'Entities/privatePresenter/Balance';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import Button from 'Control/Button.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import Checkbox from 'Control/Checkbox.vue';
import ValueBlock from 'Modules/Payment/components/ValueBlock.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';
import Asset, { IAsset } from 'Entities/publicPresenter/Asset';

interface Data {
    PlacementTypes: any;
    hasManifest: any;
    getManifest: any;
    quantity: number;
    hasAddressError: boolean;
    hasRequisitesError: boolean;
    quantityButtons: any[];
    isUserAgree: boolean;
    isCheckboxInvalid: boolean;
    submitButtonDisabled: boolean;
    showTooltip: boolean;
    DEFAULT_QUANTITY_MIN_VALUE: string;
}

interface Methods {
    shortAddress: (data: string) => string;
    openWhitelisting: () => void;
    openRequisitesWhitelisting: () => void;
    openWithdrawModal: () => void;
    setQuantityMinSize: () => void;
    setQuantityPartOfFree: (data: number) => void;
    setQuantity: (data: number) => void;
    setCurrency: (data: number) => void;
    setAddress: (data: number) => void;
    setRequisite: (data: number | undefined) => void;
    setBlockchain: (data: number) => void;
    toggleDemoAccountAlert: () => void;
    initPresetedValues: () => void;
}

interface Computed {
    activeAccountId: string;
    currentCurrencySymbol: string;
    addressesList: UserTransferAddress[];
    requisitesList: BankRequisitesResponseData[];
    filteredRequisitesList: BankRequisitesResponseData[];
    activeAddressIndex: number | undefined;
    activeRequisiteIndex: number | undefined;
    currentAddress: UserTransferAddress;
    currentRequisite: BankRequisitesResponseData | undefined;
    activeCurrencyIndex: number;
    activeBlockchainIndex: number;
    activeAccount: Account[];
    currentUser: InternalUserResponse;
    isKycVerified: boolean;
    disabledQuantityButtonsIndexes: number[];
    currentBalance: Balance | undefined;
    transferMinSize: number;
    quantityErrorText: string;
    quantityPrecision: number;
}

export default mixins(WithdrawData).extend<Data, Methods, Computed, any>({
    name: 'PaymentWithdraw',
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        presetedQuantity: {
            type: Number,
            default: undefined,
        },
        presetedCurrencyIndex: {
            type: Number,
            default: undefined,
        },
        presetedBlockchainIndex: {
            type: Number,
            default: undefined,
        },
        presetedAddressIndex: {
            type: Number,
            default: undefined,
        },
        presetedRequisiteIndex: {
            type: Number,
            default: undefined,
        },
    },
    mixins: [numberFormater, WithdrawData],
    components: {
        ModuleBlocker,
        Button,
        AssetsBlockchainsDropdown,
        Checkbox,
        ValueBlock,
        MoneyInput,
    },
    data() {
        return {
            PlacementTypes,
            hasManifest,
            getManifest,
            quantity: 0,
            hasAddressError: false,
            hasRequisitesError: false,
            DEFAULT_QUANTITY_MIN_VALUE: '0.00000001',
            quantityButtons: [
                {
                    title: 'Min',
                    callback: () => this.setQuantityMinSize(),
                },
                {
                    title: '25%',
                    callback: () => this.setQuantityPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => this.setQuantityPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => this.setQuantityPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => this.setQuantityPartOfFree(1),
                },
            ],
            isUserAgree: false,
            isCheckboxInvalid: false,
            submitButtonDisabled: false,
            showTooltip: true,
        };
    },
    computed: {
        quantityPrecision() {
            return this.GET_PRECISION;
        },
        quantityErrorText() {
            if (!this.$v.quantity.minValue) {
                return `Quantity must be greater than ${this.transferMinSize}`;
            }
            if (!this.$v.quantity.maxValue) {
                if (this.currentBalance && this.currentBalance.free > 0) {
                    return `Quantity must be lower than ${this.currentBalance ? this.currentBalance.free : 0}`;
                }
                return 'Not enough funds';
            }
            return '';
        },
        transferMinSize() {
            return this.assets.get(this.withdrawUi.asset)?.transferDetails
                ?.find((d) => d.blockchainName === this.withdrawUi.blockchain)?.transferMinSize ?? 0;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        currentCurrencySymbol() {
            if (this.withdrawUi.asset) {
                return this.withdrawUi.asset;
            }
            return '';
        },
        activeCurrencyIndex: {
            get() {
                return this.withdrawUi.currentAssetIndex;
            },
            set(index) {
                this.withdrawUi.currentAssetIndex = index;
                this.withdrawUi.asset = Array.from(this.assets.keys())[index];
            },
        },
        activeBlockchainIndex: {
            get() {
                return this.withdrawUi.currentBlockchainIndex;
            },
            set(index) {
                this.withdrawUi.currentBlockchainIndex = index;
                this.withdrawUi.blockchain = Array.from(this.blockchains.keys())[index];
            },
        },
        activeAddressIndex: {
            get() {
                return this.withdrawUi.currentAddressIndex;
            },
            set(index) {
                this.SET_ACTIVE_ADDRESS(index);
            },
        },
        activeRequisiteIndex: {
            get() {
                return this.withdrawUi.currentRequisiteIndex;
            },
            set(index) {
                this.SET_ACTIVE_REQUISITE(index);
            },
        },
        addressesList() {
            if (this.addresses && this.addresses.length > 0) {
                return this.addresses;
            }
            return [{ address: 'No whitelisted addresses', isEmpty: true }];
        },
        requisitesList() {
            if (this.requisites && this.requisites.length > 0) {
                return this.requisites;
            }
            return [{ alias: 'No whitelisted bank accounts', isEmpty: true }];
        },
        filteredRequisitesList() {
            return this.requisitesList.filter(({ asset, paymentNetwork, isActive }) => (
                asset === this.currentCurrencySymbol
                && paymentNetwork.toLowerCase() === this.withdrawUi.blockchain.toLowerCase()
                && isActive
            ));
        },
        currentAddress() {
            return this.withdrawUi.address;
        },
        currentRequisite() {
            return this.activeRequisiteIndex === undefined ? undefined : this.filteredRequisitesList[this.activeRequisiteIndex];
        },
        ...mapGetters({
            isMFADisabled: 'Auth/isMFADisabled',
            currentWithdrawalMaxSize: 'Transactions/Withdraw/currentWithdrawalMaxSize',
            quantityMaxSize: 'Transactions/Withdraw/quantityMaxSize',
            currentCurrencyBalance: 'Transactions/Withdraw/currentCurrencyBalance',
            currentCurrencyBalanceAmount: 'Transactions/Withdraw/currentCurrencyBalanceAmount',
            currentCurrencyBalanceHold: 'Transactions/Withdraw/currentCurrencyBalanceHold',
            currentCurrencyBalanceFree: 'Transactions/Withdraw/currentCurrencyBalanceFree',
            currentFees: 'Transactions/Withdraw/currentFees',
        }),
        currentBalance() {
            return this.$store.state.Balances.balances.find((b: Balance) => {
                return b.placementName === 'Single Broker'
                && b.assetSymbol === this.withdrawUi.asset
                && (b.blockchainName === this.withdrawUi.blockchain || b.assetType === 'fiat')
                && b.accountId === this.activeAccountId;
            });
        },
        activeAccount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.id === this.activeAccountId);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        disabledQuantityButtonsIndexes() {
            const result: number[] = [];
            if (!this.currentBalance) {
                return [1, 2, 3, 4];
            }
            if (this.currentBalance!.free * 0.25 < this.transferMinSize) {
                result.push(1);
            }
            if (this.currentBalance!.free * 0.5 < this.transferMinSize) {
                result.push(2);
            }
            if (this.currentBalance!.free * 0.75 < this.transferMinSize) {
                result.push(3);
            }
            if (this.currentBalance!.free < this.transferMinSize) {
                result.push(4);
            }
            return result;
        },
    },
    validations() {
        return {
            quantity: {
                minValue: minValue(this.transferMinSize),
                maxValue: maxValue(this.currentBalance ? this.currentBalance.free : 0),
            },
            currentAddress: {
                required: this.GET_IS_SELECTED_ASSET_FIAT ? false : required,
            },
            currentRequisite: {
                required: this.GET_IS_SELECTED_ASSET_FIAT ? required : false,
            },
            isUserAgree: {
                sameAs: sameAs(() => true),
            },
        };
    },
    methods: {
        toggleDemoAccountAlert() {
            if (this.$store.getters['Accounts/isActiveAccountDemo']) {
                this.$store.state.Accounts.showDemoAccountAlert = true;
                this.$store.commit('Accounts/SET_IS_DEMO_ACCOUNT_SHAKING');
            }
        },
        setQuantity(num) {
            this.quantity = this.truncateNumber(num, this.quantityPrecision);
        },
        shortAddress(string: string) {
            if (string.toLowerCase() === 'no whitelisted addresses') {
                return string;
            }
            return `${string[0] + string[1] + string[2]}...${string[string.length - 3]}${string[string.length - 2]}${string[string.length - 1]}`;
        },
        async openWhitelisting() {
            await this.prepareWhitelisting();
            await this.$router.push({
                path: '/Wallets/Confirmations/Whitelisting',
                query: {
                    assets: this.whitelistingData.assets.map((a) => a.serialize()),
                    allBlockchains: this.whitelistingData.blockchains.map((b) => b.serialize()),
                    previousRoute: this.$router.currentRoute.path,
                    selectedBlockchain: this.withdrawUi.blockchain,
                    selectedAsset: this.withdrawUi.asset,
                },
            }).catch(() => { /* navigation error */ });
        },
        async openRequisitesWhitelisting() {
            await this.$router.push({
                path: '/Wallets/Confirmations/requisites-whitelisting',
                query: {
                    previousRoute: this.$router.currentRoute.path,
                    assets: JSON.stringify(Array.from(this.assets.values()).reduce((accum, current) => {
                        if ((current as Asset).type === 'fiat') {
                            (accum as IAsset[]).push((current as Asset).serialize());
                        }
                        return accum;
                    }, [] as IAsset[])),
                },
            });
        },
        openWithdrawModal() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            this.hasAddressError = !this.currentAddress;
            this.hasRequisitesError = !this.currentRequisite;
            this.isCheckboxInvalid = !this.isUserAgree;
            if (!this.$v.$invalid) {
                if (this.GET_IS_SELECTED_ASSET_FIAT) {
                    this.$router.push({ path: '/Wallets/Confirmations/CompleteFiatWithdrawal',
                        query: {
                            withdrawUi: { ...this.withdrawUi, blockchain: this.withdrawUi.blockchain },
                            previousRoute: this.$router.currentRoute.path,
                            requisite: this.currentRequisite.serialize(),
                        },
                    });
                } else {
                    this.$router.push({ path: '/Wallets/Confirmations/CompleteWithdraw',
                        query: {
                            withdrawUi: { ...this.withdrawUi, blockchain: this.withdrawUi.blockchain },
                            address: this.withdrawUi.address.serialize(),
                            previousRoute: this.$router.currentRoute.path,
                        },
                    });
                }
            }
        },
        setQuantityMinSize() {
            const value = this.truncateNumber(this.transferMinSize, this.quantityPrecision);
            if (Number(value) === 0) {
                this.quantity = this.DEFAULT_QUANTITY_MIN_VALUE;
                return;
            }
            this.quantity = value;
        },
        setQuantityPartOfFree(factor: number) {
            if (this.currentBalance) {
                this.quantity = this.truncateNumber((this.currentBalance.free * factor), this.quantityPrecision);
            } else {
                this.quantity = 0;
            }
        },
        async setCurrency(index) {
            this.activeCurrencyIndex = index;
            this.quantity = 0;
            (this.$refs.QuantityInput as any).cleanFocusButtons();
            this.activeBlockchainIndex = 0;
            this.activeAddressIndex = undefined;
            await this.getBlockchains();
            await this.getAddresses();
        },
        async setBlockchain(index) {
            this.activeBlockchainIndex = index;
            this.quantity = 0;
            (this.$refs.QuantityInput as any).cleanFocusButtons();
            this.activeAddressIndex = undefined;
            await this.getAddresses();
        },
        async setAddress(index) {
            this.activeAddressIndex = index;
            this.quantity = 0;
            (this.$refs.QuantityInput as any).cleanFocusButtons();
        },
        async setRequisite(index) {
            this.activeRequisiteIndex = index;
            this.quantity = 0;
            (this.$refs.QuantityInput as any).cleanFocusButtons();
        },
        async initPresetedValues() {
            if (this.presetedCurrencyIndex !== undefined) {
                await this.setCurrency(this.presetedCurrencyIndex);
            }
            if (this.presetedBlockchainIndex !== undefined) {
                await this.setBlockchain(this.presetedBlockchainIndex);
            }
            if (this.presetedAddressIndex !== undefined) {
                await this.setAddress(this.presetedAddressIndex);
            }
            if (this.presetedRequisiteIndex !== undefined) {
                await this.setRequisite(this.presetedRequisiteIndex);
            }
            if (this.presetedQuantity !== undefined) {
                this.quantity = this.presetedQuantity;
            }
        },
    },
    async mounted() {
        await this.init();
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });
        await this.getRequisites(this.activeAccountId);
        await this.initPresetedValues();
    },
    watch: {
        async activeAccountId() {
            await this.getAddresses();
            await this.getRequisites(this.activeAccountId);
        },
        async quantity(val) {
            await this.SET_AMOUNT(val);
            this.$emit('change-quantity', val);
            this.$v.quantity.$reset();
        },
        activeRequisiteIndex(val) {
            this.$emit('change-requisite', val);
        },
        activeAddressIndex(val) {
            this.$emit('change-address', val);
        },
        activeBlockchainIndex(val) {
            this.$emit('change-blockchain', val);
        },
        activeCurrencyIndex(val) {
            this.$emit('change-currency', val);
            this.setRequisite(undefined);
        },
    },
});
