
import mixins from 'vue-typed-mixins';

import TransferHistoryData from 'Modules/TransferHistory/TransferHistory.Data.vue';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import ApiError from 'Entities/ApiError';
import WalletsApi from 'Apis/Wallets';
import UserTransferAddressesParams from 'Entities/privatePresenter/UserTransferAddressesParams';
import CreateDepositRequestData, { ICreateDepositRequestData } from 'Entities/walletExecutor/CreateDepositRequestData';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import { needUpdateTransferHistory } from 'Store/v2/UiActions';
import Asset from 'Entities/publicPresenter/Asset';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import PublicApi from 'Apis/PublicData';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import BlockchainRequest from 'Entities/publicPresenter/BlockchainRequest';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';
import UserBankRequisitesRequestData from 'Entities/walletExecutor/UserBankRequisitesRequestData';

export interface DepositUI {
    currentAssetIndex: number, // asset index
    currentBlockchainIndex: number, // blockchain index
    currentAddressIndex: number | undefined,
    currentRequisiteIndex: number | undefined,
    asset: string,
    blockchain: string,
    address: null | UserTransferAddress,
    requisite: null | BankRequisitesResponseData,
    destinationAddress: null | WalletAddressResponseData | EmptyResult,
    amount: number,
    transferId: string,
    fee: {
        amount: number;
        assetSymbol: string;
    },
}

interface Data {
    addresses: UserTransferAddress[];
    requisites: BankRequisitesResponseData[];
    clickedDeposit: any;
    assets: Map<string, Asset>;
    blockchains: Map<string, Blockchain>;
    depositUi: DepositUI;
    whitelistingData: { assets: Asset[]; blockchains: Blockchain[]; };
}

interface Methods {
    // mutations
    SET_ACTIVE_ADDRESS: (data: number) => void;
    SET_ACTIVE_REQUISITE: (data: number) => void;
    SET_AMOUNT: (data: number) => void;
    SET_CLICKED_DEPOSIT: (data: any) => void;
    SET_ASSETS: (data: Asset[]) => void;
    SET_BLOCKCHAINS: (data: Blockchain[]) => void;
    // actions
    init: () => void;
    getAssets: () => void;
    getBlockchains: () => void;
    getAddresses: () => void;
    getRequisites: (data: string) => void;
    createDeposit: (data: string) => void;
    getAddress: (data: string) => void;
    prepareWhitelisting: () => void;
}

interface Computed {
    GET_IS_SELECTED_ASSET_FIAT: boolean;
    GET_PRECISION: number;
}

export default mixins(TransferHistoryData).extend<Data, Methods, Computed>({
    mixins: [TransferHistoryData],
    // state
    data() {
        return {
            addresses: [],
            requisites: [],
            assets: new Map<string, Asset>(),
            blockchains: new Map<string, Blockchain>(),
            clickedDeposit: null,
            depositUi: {
                currentAssetIndex: 0,
                currentBlockchainIndex: 0,
                currentAddressIndex: undefined,
                currentRequisiteIndex: undefined,
                asset: '',
                blockchain: '',
                address: null,
                requisite: null,
                destinationAddress: null,
                amount: 0,
                transferId: '',
                fee: {
                    amount: 0,
                    assetSymbol: '',
                },
            } as DepositUI,
            whitelistingData: {
                assets: [] as Asset[],
                blockchains: [] as Blockchain[],
            },
        };
    },
    // getters
    computed: {
        GET_IS_SELECTED_ASSET_FIAT() {
            return this.assets.get(this.depositUi.asset)?.type === 'fiat';
        },
        GET_PRECISION() {
            const asset = this.assets.get(this.depositUi.asset);
            if (!asset) {
                return 8;
            }
            const stringMultiplier = String(asset.multiplier);
            return stringMultiplier.length - stringMultiplier.replace(/0/g, '').length;
        },
    },
    // mutations and actions
    methods: {
        // mutations
        SET_ACTIVE_ADDRESS(addressIndex: number) {
            this.depositUi.currentAddressIndex = addressIndex;
            this.depositUi.address = this.addresses[addressIndex];
        },
        SET_ACTIVE_REQUISITE(requisiteIndex: number) {
            this.depositUi.currentRequisiteIndex = requisiteIndex;
            this.depositUi.requisite = this.requisites[requisiteIndex];
        },
        SET_AMOUNT(amount: number) {
            this.depositUi.amount = amount;
        },
        SET_CLICKED_DEPOSIT(data: any) {
            this.clickedDeposit = data;
        },
        SET_ASSETS(assets) {
            const map = new Map<string, Asset>();
            assets.forEach((a) => {
                map.set(a.symbol, a);
            });
            this.assets = map;
        },
        SET_BLOCKCHAINS(blockchains) {
            const map = new Map<string, Blockchain>();
            blockchains.forEach((b) => {
                map.set(b.name, b);
            });
            this.blockchains = map;
        },
        // actions
        async init() {
            await this.getAssets();
            await this.getBlockchains();
            await this.getAddresses();
        },
        async getAssets() {
            try {
                const { data: assets } = await PublicApi.publicGetAssets(new AssetsRequest({
                    page: 1,
                    perPage: 256,
                    transferable: true,
                    includeTransferDetails: true,
                    fromPlacementName: 'Single Broker',
                }));
                this.depositUi.asset = assets[0].symbol;
                this.SET_ASSETS(assets);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting assets');
                }
            }
        },
        async getRequisites(accountId: string) {
            try {
                const { data: requisites } = await WalletsApi.listUserBankRequisites(new UserBankRequisitesRequestData({
                    accountId,
                }));
                this.requisites = requisites;
            } catch {
                this.requisites = [];
                this.depositUi.requisite = null;
            }
        },
        async getBlockchains() {
            try {
                const blockchainsSet = new Set<string>();
                const details = this.assets.get(this.depositUi.asset).transferDetails;
                details!.forEach((d) => {
                    blockchainsSet.add(d.blockchainName);
                });
                const blockchains: Blockchain[] = [];
                for (let i = 0; i < blockchainsSet.size; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: blockchain } = await PublicApi.publicGetBlockchain(new BlockchainRequest({
                        name: Array.from(blockchainsSet)[i],
                    }));
                    blockchains.push(blockchain);
                }
                this.depositUi.blockchain = blockchains[0].name;
                this.SET_BLOCKCHAINS(blockchains);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting blockchains');
                }
            }
        },
        async getAddresses() {
            const accountId = this.$store.state.Accounts.activeAccountID;
            try {
                const { data: addresses } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                    blockchainName: this.depositUi.blockchain,
                    status: 'APPROVED',
                    accountId,
                }));
                this.addresses = addresses.filter(({ assetSymbols }) => assetSymbols.includes(this.depositUi.asset));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting addresses');
                }
            }
        },
        async createDeposit(accountId: string, requisiteAlias: string | undefined = undefined) {
            let requestData = {} as ICreateDepositRequestData;
            if (this.GET_IS_SELECTED_ASSET_FIAT) {
                requestData = {
                    accountId,
                    assetSymbol: this.depositUi.asset,
                    blockchainName: this.depositUi.blockchain,
                    amount: this.depositUi.amount,
                    fiatTransferDetails: {
                        alias: requisiteAlias ?? (this.depositUi.requisite?.alias ?? ''),
                    },
                };
            } else if (this.depositUi.address!.memo) {
                requestData = {
                    accountId,
                    address: this.depositUi.address!.address,
                    assetSymbol: this.depositUi.asset,
                    blockchainName: this.depositUi.address?.blockchainName ?? '',
                    amount: this.depositUi.amount,
                    memo: this.depositUi.address!.memo,
                };
            } else {
                requestData = {
                    accountId,
                    address: this.depositUi.address!.address,
                    assetSymbol: this.depositUi.asset,
                    blockchainName: this.depositUi.address?.blockchainName ?? '',
                    amount: this.depositUi.amount,
                };
            }
            try {
                this.$store.commit(SET_LOADING_ON(undefined), { root: true });
                const { data: res } = await WalletsApi.createDeposit(new CreateDepositRequestData(requestData));
                const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                    id: res.transferId,
                }));
                this.UPDATE_TRANSFER(transfer.serialize());
                this.depositUi.transferId = res.transferId;
                if (transfer?.commission && transfer?.commission.length > 0) {
                    this.depositUi.fee.amount = transfer.commission[0].value;
                    this.depositUi.fee.assetSymbol = transfer.commission[0].assetSymbol;
                }
                if (!this.GET_IS_SELECTED_ASSET_FIAT) {
                    await this.getAddress(accountId);
                }
                await this.$store.dispatch('Notificator/showSuccessNotification', `Deposit has been ${transfer.status}`);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during performing deposit', { root: true });
                }
            } finally {
                await this.$store.dispatch(needUpdateTransferHistory(undefined), { root: true });
                this.$store.commit(SET_LOADING_OFF(undefined), { root: true });
            }
        },
        async getAddress(accountId: string) {
            try {
                const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                    accountId,
                    assetSymbol: this.depositUi.asset,
                    blockchainName: this.depositUi.address?.blockchainName ?? '',
                }));
                this.depositUi.destinationAddress = res;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting address', { root: true });
                }
            }
        },
        async prepareWhitelisting() {
            const { data: assets } = await PublicApi.publicGetAssets(new AssetsRequest({
                transferable: true,
                includeTransferDetails: true,
                perPage: 256,
                fromPlacementName: 'Single Broker',
            }));
            const { data: blockchains } = await PublicApi.publicGetBlockchains(new BlockchainsRequest({
                perPage: 256,
                placementName: 'Single Broker',
            }));
            this.whitelistingData.assets = assets;
            this.whitelistingData.blockchains = blockchains;
        },
    },
    async mounted() {
        await this.$store.dispatch('VuexEventListener/addActionListener', {
            type: 'UiActions/needUpdateAddresses',
            callback: async () => {
                await this.getAddresses();
            },
        });
    },
});
