<template>
    <div
        :class="s.container"
        :style="`width: ${width}%;`"
    >
        <popper
            trigger="hover"
            :options="{
                placement: 'bottom',
            }"
            :class="s.balancePopper"
        >
            <div class="popper" :class="s.popper">
                <PlacementBalancePopover :is-aggregated-by-placements="isAggregatedByPlacements" :balance="balance" :placement-name="placement" />
            </div>
            <div slot="reference">
                <div :class="s.icon">
                    <CryptoIcon v-if="isAggregatedByPlacements" :symbol="placement" :size="10" />
                    <PlacementIcon v-else :placement="placement" :size="10" />
                </div>
                <div :class="s.bar" :style="`background-color: ${background(placement)}`">
                    <div :style="`background-color: ${background(placement)}; width: ${holdWidth}%`"></div>
                </div>
            </div>
        </popper>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getPlacementThemeColors } from 'Config/placementsManifest';
import { getManifest } from 'Models/assetsManifest';

import PlacementBalancePopover from './PlacementBalancePopover.vue';

export default {
    name: 'PlacementBalanceRow',
    components: {
        PlacementBalancePopover,
    },
    props: {
        placement: {
            type: String,
            required: true,
        },
        balance: {
            type: Object,
            required: true,
        },
        isAggregatedByPlacements: {
            type: Boolean,
            required: true,
        },
        totalScore: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        balanceTotalEquivalent() {
            if (this.isAggregatedByPlacements) {
                return this.balance.totalEquivalent;
            }
            return this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](this.balance) || 0;
        },
        highAggregatedBalanceScore() {
            if (this.isAggregatedByPlacements) {
                return this.totalScore;
            }
            return this.$store.getters['Balances/GET_BALANCES']
                .filter(({ accountId, assetSymbol }) => assetSymbol === this.balance.assetSymbol && accountId === this.activeAccountId)
                .reduce((accum, currentBalance) => {
                    return accum + this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](currentBalance) || 0;
                }, 0);
        },
        width() {
            if (this.isAggregatedByPlacements) {
                if (this.balanceTotalEquivalent / this.highAggregatedBalanceScore > 100) {
                    return 100;
                }
                return (this.balanceTotalEquivalent / this.highAggregatedBalanceScore) * 100;
            }

            if ((this.balance.total / this.totalScore) > 100) {
                return 100;
            }
            return (this.balance.total / this.totalScore) * 100;
        },
        holdWidth() {
            return ((this.balance.hold + this.balance.positionsHold) / this.balance.total) * 100;
        },
    },
    methods: {
        placementThemeColors(placement) {
            return getPlacementThemeColors(placement);
        },
        assetsThemeColors(assetSymbol) {
            return getManifest(assetSymbol).color;
        },
        background(symbol) {
            // eslint-disable-next-line no-nested-ternary
            return this.isAggregatedByPlacements ? `${this.assetsThemeColors(symbol)}` : this.placementThemeColors(symbol) ? this.placementThemeColors(symbol).main : 'rgba(101, 111, 219, 0.35)';
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 30px;
    padding-left: 17px;
    transition: width 0.3s ease-out;
    margin-top: -7px;
    &:last-child {
        & .bar {
            border-radius: 4px;
        }
    }
    & .bar {
        position: relative;
        width: 100%;
        height: 5px;
        & * {
            height: 5px;
            filter: brightness(60%);
        }
    }
    & .icon {
        position: absolute;
        left: 0;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        box-shadow: 0 1px 6px var(--cl-black-light);
        border-radius: 9px;
        background: var(--cl-white);
        z-index: 1;
    }
    & .balancePopper {
        width: 100%;
        height: 100%;
        padding-top: var(--m-s);
    }
    .popper {
        background-color: var(--cl-white);
        border: 2px solid var(--cl-violet);
        border-radius: 8px;
        min-width: unset;
    }
}
</style>
