
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { OrdersStatuses } from 'Models/ordersHistory';
import { getOrderTrades, QUANTITY_TYPES, resolveOrderType } from 'Store/v2/Futures';
import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';
import FuturesTrade from 'Entities/privatePresenter/FuturesTrade';
import Placement from 'Entities/publicPresenter/Placement';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import FuturesApi from 'Apis/Futures';
import CancelOrderRequest from 'Entities/futuresOrderRegistrar/CancelOrderRequest';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Guid from 'UI/Guid.vue';
import Preloader from 'Common/Preloader.vue';

import FuturesTradeRow from './FuturesTradeRow.vue';

interface Data {
    isOpen: boolean;
    contractPrice: number;
    isCancelOrderDisabled: boolean;
    stringToFixedDigits: any;
    theme: any;
    resolveOrderType: (type: string) => string;
}

interface Methods {
    toggleDetails: () => void;
    dateMaker: (data: string) => string;
    cancelOrder: () => void;
}

interface Computed {
    orderTrades: FuturesTrade[];
    isTransparent: boolean;
    averagePrice: number;
    price: number;
    executedQuantity: string;
    orderPlacement: Placement;
    quantityType: string;
    executedTotal: number;
    handledExecutedTotal: string;
    summaryFee: number;
    isCancelable: boolean;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    handledQuantity: string;
    showUserInputData: boolean;
    isCoinMarginOrder: boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        FuturesTradeRow,
        AssetValue,
        Guid,
        Preloader,
    },
    props: {
        order: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
            contractPrice: 0,
            isCancelOrderDisabled: false,
            QUANTITY_TYPES,
            stringToFixedDigits,
            resolveOrderType,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        isCoinMarginOrder() {
            return this.order.contractSymbol.split('/')[1] === 'USD';
        },
        orderTrades() {
            return this.$store.state.Futures.ordersTradesMap.get(`${this.order.accountId}, ${this.order.id}`) || [];
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        isTransparent() {
            return [OrdersStatuses.CANCELED.value, OrdersStatuses.REJECTED.value].indexOf(this.order.status) !== -1;
        },
        averagePrice() {
            return this.order.priceAvg;
        },
        price() {
            return this.order.price ?? 0;
        },
        executedQuantity() {
            const result = this.orderTrades.reduce((last, next) => last + Number(next.quantity), 0);
            return Number.isNaN(result) ? '0' : result.toFixed(8).noExponents().getSeparatedDigits();
        },
        handledQuantity() {
            const type = this.order.orderType.replace('_', ' ').toLowerCase();
            const status = this.order.status.toLowerCase();
            if ((type === 'take profit' || type === 'stop loss') && status !== 'filled' && status !== 'canceled') {
                return '100%';
            }

            if (this.quantityType === QUANTITY_TYPES.base) {
                if (this.isCoinMarginOrder) {
                    return this.executedQuantity;
                }
                return stringToFixedDigits(String(this.executedQuantity), 8);
            }
            return this.executedQuantity;
        },
        orderPlacement() {
            return this.$store.getters['Placements/getPlacementByName'](this.order.placementName);
        },
        quantityType() {
            const placementOrderTypes = (this.orderPlacement as any).orderQuantityTypes;
            return (placementOrderTypes.MARKET)[this.order.isBuy ? 'BUY' : 'SELL'];
        },
        showUserInputData() {
            return (this.order.orderType === 'LIMIT' || this.order.orderType === 'LIMIT_STOP') && this.order.status !== 'FILLED';
        },
        executedTotal() {
            if (this.quantityType === QUANTITY_TYPES.base) {
                if (this.orderTrades.length === 0) {
                    return 0;
                }
                const result = this.orderTrades.reduce((prev, next) => prev + next[this.isCoinMarginOrder ? 'baseQuantity' : 'quoteQuantity'], 0);
                return Number.isNaN(result) ? 0 : result;
            }
            if (!this.contractPrice) {
                return 0;
            }
            if (this.orderTrades.length === 0) {
                return 0;
            }
            const result = this.orderTrades.reduce((prev, next) => prev + next.quoteQuantity, 0);
            return Number.isNaN(result) ? 0 : result;
        },
        handledExecutedTotal() {
            const type = this.order.orderType.replace('_', ' ').toLowerCase();
            const status = this.order.status.toLowerCase();
            if ((type === 'take profit' || type === 'stop loss') && status !== 'filled' && status !== 'canceled') {
                return '100%';
            }

            return stringToFixedDigits(this.executedTotal.noExponents(), 4).getSeparatedDigits();
        },
        summaryFee() {
            const result = this.orderTrades.reduce((prev, next) => prev + Number(next.commissionQuantity), 0);
            return Number.isNaN(result) ? 0 : result;
        },
        isCancelable() {
            if (this.order.orderType.toLowerCase().indexOf('market') !== -1) {
                return false;
            }
            return !(this.order.status === 'FILLED' || this.order.status === 'CANCELED' || this.order.status === 'REJECTED' || this.order.initiatorType === 'placements');
        },
    },
    methods: {
        async toggleDetails() {
            this.isOpen = !this.isOpen;
        },
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            if (!date) {
                return '';
            }
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        async cancelOrder() {
            if (this.isCancelOrderDisabled || !this.isKycVerified) {
                return;
            }
            this.isCancelOrderDisabled = true;
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await FuturesApi.privateFuturesCancelOrder(new CancelOrderRequest({
                    id: this.order.id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during cancelling order');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
    async mounted() {
        if (this.quantityType === QUANTITY_TYPES.contract) {
            const { data: { contractPrice } } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                placementName: this.orderPlacement.name,
                symbol: this.order.contractSymbol,
            }));
            this.contractPrice = Number(contractPrice);
        }
        await this.$store.dispatch(getOrderTrades({ accountId: this.order.accountId, orderId: this.order.id }));
    },
});
