<template>
    <div class="module doughnut-chart-module">
        <BlockHeader class="draggable" title="Current Allocation">
            <ChartToggler v-model="showChart" />
        </BlockHeader>
        <div :class="['module-content', { 'is-overflowed': !showChart }]">
            <div
                v-show="!showChart"
                :class="s.relative"
            >
                <DoughnutChartDatasetList
                    v-if="placementsBalances.length > 0"
                    :items="placementsBalances"
                >
                    <template #item-title="slotProps">
                        <div :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.placementName" />
                            <span>
                            {{ slotProps.itemData.placementName }}
                        </span>
                        </div>
                    </template>
                    <template #item-value="slotProps">
                        <span>{{ quotationAssetCharacter }}</span>{{ slotProps.itemData.totalEquivalent.round(quotationAssetPrecision).getSeparatedDigits() }}
                    </template>
                    <template #item-percentage="slotProps"> {{ slotProps.itemData.part }}%</template>
                </DoughnutChartDatasetList>
                <NoData v-else />
            </div>
            <div class="chart-container" v-show="showChart">
                <doughnut-chart
                    :chart-data="data"
                    :tooltip-label-callback="tooltipLabelCallback"
                    v-if="isDataAvailable"
                    :layout-paddings="layoutPaddings"
                    :style="styles"
                />
                <EmptyBarChart v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getPlacementStyle } from 'Config/placementsManifest';
import BlockHeader from 'UI/BlockHeader.vue';
import ChartToggler from 'UI/ChartToggler.vue';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import NoData from 'UI/NoData.vue';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

import DoughnutChartDatasetList from '../../Portfolios/components/DoughnutChartDatasetList.vue';

export default {
    name: 'TradesPerExchange',
    components: {
        NoData,
        DoughnutChartDatasetList,
        BlockHeader,
        ChartToggler,
    },
    data() {
        return {
            MAXIMUM_SECTOR_INDEX: 5,
            MINIMUM_SECTOR_PERCENT: 5,
            styles: {
                width: '300px',
            },
            layoutPaddings: {
                left: 90,
                right: 90,
                top: 0,
                bottom: 60,
            },

            showChart: true,
        };
    },
    computed: {
        ...mapGetters({
            balancesByPlacements: 'Balances/GET_BALANCES_BY_PLACEMENTS',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },
        totalScore() {
            if (this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT) {
                return this.$store.state.Balances.balances.reduce((accum, current) => {
                    let temp = accum;
                    const currentPlacement = this.$store.state.Placements.placements.find(({ name }) => name === current.placementName);
                    if (
                        current.accountId === this.activeAccountId
                        && currentPlacement
                        && (
                            !UnifiedExchangesTags.has(currentPlacement.tag)
                            || currentPlacement.type === 'crypto-spot'
                        )
                    ) {
                        temp += (current.quotation * current.total);
                    }
                    return temp;
                }, 0);
            }

            const ids = this.$store.getters['Portfolio/requestAccountsData'].accountIds;
            return this.$store.state.Balances.balances.reduce((accum, current) => {
                let temp = accum;
                if (ids.indexOf(current.accountId) !== -1) {
                    temp += (current.quotation * current.total);
                }
                return temp;
            }, 0);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        placementsBalances() {
            const ids = this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT
                ? [this.activeAccountId]
                : this.$store.getters['Portfolio/requestAccountsData'].accountIds;

            return this.$store.state.Balances.balances
                .reduce((accum, current) => {
                    const currentPlacement = this.$store.state.Placements.placements.find(({ name }) => name === current.placementName);
                    if (
                        ids.indexOf(current.accountId) !== -1
                        && current.quotation > 0
                        && current.total > 0
                        && currentPlacement
                        && (
                            !UnifiedExchangesTags.has(currentPlacement.tag)
                            || currentPlacement.type === 'crypto-spot'
                        )
                    ) {
                        const index = accum.findIndex(({ placementName }) => placementName === current.placementName);
                        if (index !== -1) {
                            accum.splice(index, 1, {
                                placementName: accum[index].placementName,
                                placementStyle: accum[index].placementStyle,
                                part: (Number(accum[index].part) + (((current.quotation * current.total) / (this.totalScore || Infinity)) * 100)).toFixed(2),
                                totalEquivalent: accum[index].totalEquivalent + (current.quotation * current.total),
                            });
                        } else {
                            accum.push({
                                placementName: current.placementName,
                                placementStyle: getPlacementStyle(current.placementName),
                                part: (((current.quotation * current.total) / (this.totalScore || Infinity)) * 100).toFixed(2),
                                totalEquivalent: (current.quotation * current.total),
                            });
                        }
                    }
                    return accum;
                }, [])
                .sort(({ totalEquivalent: a }, { totalEquivalent: b }) => b - a);
        },

        isDataAvailable() {
            return this.placementsBalances.length > 0;
        },

        chartLabels() {
            return this.placementsBalances.map(({ placementName }) => placementName);
        },
        chartDatasetData() {
            return this.placementsBalances.map(({ totalEquivalent }) => totalEquivalent);
        },
        data() {
            const summaryBalance = this.chartDatasetData.reduce((prev, next) => prev + Number(next), 0);
            let isLastElementAdded = false;
            const truncatedDatasetData = this.chartDatasetData.reduce((accum, current, index) => {
                if (
                    (current / summaryBalance) * 100 > this.MINIMUM_SECTOR_PERCENT
                    && index < this.MAXIMUM_SECTOR_INDEX
                ) {
                    accum.push(current);
                } else if (isLastElementAdded) {
                    accum.splice(
                        accum.length - 1,
                        1,
                        accum[accum.length - 1] + current,
                    );
                } else {
                    accum.push(current);
                    isLastElementAdded = true;
                }
                return accum;
            }, []);
            const truncatedLabels = this.chartLabels.reduce((accum, label, index) => {
                if (
                    index < truncatedDatasetData.length - 1
                    || (truncatedDatasetData.length === 1 && index === 0)
                ) {
                    accum.push(label);
                } else if (index === truncatedDatasetData.length - 1) {
                    accum.push('Other');
                }
                return accum;
            }, []);

            return {
                labels: truncatedLabels,
                datasets: [
                    {
                        backgroundColor: this.placementsBalances.map(({ placementStyle }) => placementStyle.theme_colors.main),
                        hoverBackgroundColor: this.placementsBalances.map(
                            ({ placementStyle }) => placementStyle.theme_colors.lighter,
                        ),
                        borderWidth: 1,
                        borderColor: this.$store.getters.isThemeDark ? '#2C2C36' : 'white',
                        data: truncatedDatasetData,
                        config: {
                            part: this.placementsBalances.map(({ part }) => part),
                        },
                        datalabels: {
                            display: true,
                            formatter(_, ctx) {
                                let part = ctx.dataIndex < (truncatedLabels.length - 1)
                                    ? ctx.dataset.config.part[ctx.dataIndex]
                                    : ctx.dataset.config.part.reduce((accum, current, index) => {
                                        let temp = Number(accum);
                                        if (index >= (truncatedLabels.length - 1)) {
                                            temp += Number(current);
                                        }
                                        return temp.toFixed(2);
                                    }, 0);
                                if (Number(part) === 0) {
                                    part = '< 0.01';
                                }
                                return `${ctx.chart.data.labels[ctx.dataIndex]}\n${part}%`;
                            },
                            backgroundColor(ctx) {
                                return ctx.dataset.backgroundColor[ctx.dataIndex];
                            },
                        },
                    },
                ],
            };
        },
    },
    methods: {
        tooltipLabelCallback({ index }, { datasets: [{ data }], labels }) {
            const currentLabel = labels[index];

            return `${currentLabel}: ${this.quotationAssetCharacter}${data[index]
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
}
.relative {
    position: relative;
}
</style>
