<template>
    <div class="module">
        <BlockHeader
            title="Balance History"
            :with-search="true"
            @on-search="onSearch"
            class="draggable"
        >
            <MultiplySelectBox :items="legendAssets" title="Assets" v-model="assetsToShow"/>
        </BlockHeader>
        <Preloader
            v-if="isLoading"
            :class="s.mAuto"
        />
        <div
            v-else
            ref="layout"
            :class="s.h100"
        >
            <StackedBarChart
                :chart-data="chartData"
                :styles="styles"
                :tooltip-label-callback="tooltipLabelCallback"
                :tooltip-title-callback="tooltipTitleCallback"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { timeConstants } from 'Config/timeConstants';
import BlockHeader from 'UI/BlockHeader.vue';
import MultiplySelectBox from 'Components/FormElements/MultiplySelectBox.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import StackedBarChart from 'Components/Charts/StackedBarChart.vue';

import BalanceByPeriodData from './BalanceByPeriod.Data.vue';

export default {
    name: 'BalanceByPeriodChart',
    mixins: [BalanceByPeriodData],
    components: {
        StackedBarChart,
        BlockHeader,
        MultiplySelectBox,
    },
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
        module: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.activeAccountId && this.balancesLength > 0) {
            this.getData(this.range, this.module);
        }
    },
    data() {
        return {
            styles: {
                height: '540px',
                width: '100%',
                'margin-top': '20px',
            },

            assetsToShow: [],

            searchQuery: '',
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            activeAccount: 'Accounts/activeAccount',
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
        }),
        chartBorderColor() {
            return this.isThemeDark ? undefined : '#fff';
        },
        labels() {
            const withNow = this.range.end > Date.now() - timeConstants.DAY;
            const labels = this.generateLabels(this.range.start, this.range.end, { withNow, hasAdditionalLabel: !withNow });
            return labels;
        },

        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },

        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        balancesStatistics() {
            if (!this.balancesStatisticsSource) {
                return [];
            }
            return this.balancesStatisticsSource
                .filter(
                    ({ assetSymbol }) => !this.searchQuery || assetSymbol.toUpperCase().indexOf(this.searchQuery.toUpperCase()) !== -1,
                )
                .filter(({ records }) => Object.values(records).some(({ quoted }) => quoted !== 0));
        },

        startToday() {
            return new Date(new Date().setHours(0, 0, 0, 0));
        },
        UTCStartToday() {
            return new Date(Date.UTC(this.startToday.getFullYear(), this.startToday.getMonth(), this.startToday.getDate()));
        },
        UTCStartTodayTimestamp() {
            return this.UTCStartToday.getTime();
        },

        legendAssets() {
            if (!this.balancesStatistics) {
                return [];
            }
            return this.balancesStatistics.map(({ assetSymbol }) => assetSymbol);
        },
        chartData() {
            const datasets = [...this.chartDatasets];

            const other = {
                assetSymbol: 'Other',
                backgroundColor: 'rgba(140, 145, 142, 0.6)',
                borderWidth: 2,
                borderColor: this.chartBorderColor,
                lineTension: 0,
                pointRadius: 0,
                fill: 'origin',
                pointHoverRadius: 0,
                pointHitRadius: 3,
                config: {
                    assetSymbol: 'Other',
                    assetPrecision: 2,
                    baseBalance: [],
                    index: datasets.length,
                },
                data: [],
                datalabels: {
                    labels: {
                        title: null,
                    },
                },
            };
            for (let i = 0; i < this.chartLabels.length; i += 1) {
                other.data[i] = 0;
                other.config.baseBalance[i] = 0;
                const summaryBalance = datasets.reduce((accum, current) => accum + current.data[i], 0);
                datasets.forEach((el) => {
                    if (Math.abs(el.data[i] / summaryBalance) < 0.05) {
                        other.data[i] += el.data[i];
                        other.config.baseBalance[i] += el.data[i];
                        el.data[i] = null;
                    }
                });
            }
            datasets.push(other);

            const transposed = this.chartLabels.map((_, i) => (
                datasets.map((ds) => ({
                    ...ds,
                    assetSymbol: ds.assetSymbol,
                    value: ds.data[i],
                    baseValue: ds.config.baseBalance[i],
                    color: ds.backgroundColor,
                    borderColor: this.chartBorderColor,
                }))
            ));

            const sortedTransposed = transposed.map((column) => (
                column.sort((a, b) => {
                    if (a.assetSymbol === 'Other') {
                        return -1;
                    }
                    if (b.assetSymbol === 'Other') {
                        return 1;
                    }
                    return a.value - b.value;
                })
            ));

            const sortedDatasets = datasets.map((ds, datasetIndex) => ({
                ...ds,
                data: sortedTransposed.map((column) => column[datasetIndex]?.value ?? null),
                assetSymbol: sortedTransposed.map((column) => column[datasetIndex]?.assetSymbol ?? ds.assetSymbol),
                backgroundColor: sortedTransposed.map((column) => column[datasetIndex]?.color ?? ds.backgroundColor),
                borderColor: this.chartBorderColor,
                config: {
                    ...ds.config,
                    assetSymbol: sortedTransposed.map((column) => column[datasetIndex]?.assetSymbol ?? ds.assetSymbol),
                    baseBalance: sortedTransposed.map((column) => column[datasetIndex]?.baseValue ?? 0),
                },
            }));

            const barsDataSet = [
                ...sortedDatasets.map((dataset) => {
                    return {
                        ...dataset,
                        data: dataset.data.map((d) => (d >= 0 ? d : null)),
                        stack: '0',
                        barPercentage: 1,
                        categoryPercentage: 1,
                    };
                }),
                ...sortedDatasets.map((dataset, index) => {
                    return {
                        ...dataset,
                        data: dataset.data.map((d) => (d < 0 ? d : null)),
                        stack: '1',
                        barPercentage: 1,
                        categoryPercentage: 1,
                        config: {
                            ...dataset.config,
                            index: index + sortedDatasets.length,
                        },
                    };
                }),
            ];

            const lineDataSet = {
                label: 'Line Dataset',
                type: 'line',
                borderColor: '#656FDB',
                backgroundColor: 'rgba(35, 36, 77, 0)',
                pointRadius: 0,
                pointHitRadius: 8,
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                data: this.chartLabels.reduce((accum, _, index) => {
                    accum.push(barsDataSet.reduce((sum, current) => sum + current.data[index], 0));
                    return accum;
                }, []),
            };

            return {
                labels: this.chartLabels,
                datasets: [...barsDataSet, lineDataSet],
            };
        },
        chartLabels() {
            if (!this.labels || this.labels.length < 2) {
                return this.labels;
            }
            const diff = this.labels[1] - this.labels[0];
            return this.labels.map((el, index, labels) => {
                if (index === 0) {
                    return el;
                }
                return labels[index - 1] + diff;
            });
        },
        chartDatasets() {
            const balances = this.balancesStatistics;
            if (!balances) {
                return [];
            }

            return balances
                .filter(({ assetSymbol }) => !this.isAssetFiltered(assetSymbol))
                .sort((a, b) => a.total - b.total)
                .map(
                    (
                        {
                            assetSymbol, assetPrecision, records, chartColors: { background: backgroundColor },
                        },
                        index,
                    ) => ({
                        assetSymbol,
                        backgroundColor,
                        borderWidth: 2,
                        borderColor: this.chartBorderColor,
                        lineTension: 0,
                        pointRadius: 0,
                        fill: index > 0 ? '-1' : 'origin',
                        pointHoverRadius: 0,
                        pointHitRadius: 3,
                        config: {
                            assetSymbol,
                            assetPrecision,
                            baseBalance: Object.values(records).map(({ balance }) => balance),
                            index,
                        },
                        data: Object.values(records).map(({ quoted }) => quoted),
                        datalabels: {
                            labels: {
                                title: null,
                            },
                        },
                    }),
                );
        },
        balancesLength() {
            return this.$store.state.Balances.balances.length;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        onSearch(e) {
            this.searchQuery = e;
        },
        tooltipTitleCallback(ctx) {
            if (ctx[0].dataset.type === 'line') {
                const dateString = ctx[0].label;
                const index = ctx[0].dataIndex;
                const summaryBalance = this.balancesStatistics
                    .reduce((accum, current) => {
                        let temp = accum;
                        const { quoted } = Object.values(current.records)[index];
                        if (quoted) {
                            temp += quoted;
                        }
                        return temp;
                    }, 0);
                return [
                    this.$store.getters.getTimeDateString({ timestamp: Date.parse(dateString.replace('a.m.', 'AM').replace('p.m.', 'PM')) }),
                    `Summary Balance: ${this.quotationAssetCharacter}${summaryBalance.toFixed(this.quotationAssetPrecision).getSeparatedDigits()}`,
                ];
            }

            const dateString = ctx[0].label;
            return this.$store.getters.getTimeDateString({ timestamp: Date.parse(dateString.replace('a.m.', 'AM').replace('p.m.', 'PM')) });
        },
        tooltipItemSortCallback(a, b) {
            const aValue = Number(a.dataset.data[a.dataIndex]);
            const bValue = Number(b.dataset.data[b.dataIndex]);
            return bValue - aValue;
        },
        tooltipLabelCallback(ctx) {
            if (ctx.dataset.type === 'line') {
                const index = ctx.dataIndex;
                let resultArray = this.balancesStatistics
                    .reduce((accum, current) => {
                        const { quoted, balance } = Object.values(current.records)[index];
                        if (quoted && balance) {
                            accum.push({
                                balance: quoted,
                                value: `${current.assetSymbol}: ${(Number(balance))
                                    .floor(current.assetPrecision)
                                    .noExponents()
                                    .getSeparatedDigits()}; ${this.quotationAssetSymbol}: ${(+quoted)
                                    .toFixed(this.quotationAssetPrecision)
                                    .getSeparatedDigits()}`,
                            });
                        }
                        return accum;
                    }, [])
                    .sort(({ balance: a }, { balance: b }) => b - a);

                const summaryBalance = resultArray.reduce((accum, current) => accum + current.balance, 0);
                resultArray = resultArray.filter((_, index) => index < 9);
                const newSummaryBalance = resultArray.reduce((accum, current) => accum + current.balance, 0);
                resultArray = resultArray.map(({ value }) => value);
                resultArray.push(
                    `Other: ${this.quotationAssetSymbol}: ${(summaryBalance - newSummaryBalance)
                        .toFixed(this.quotationAssetPrecision)
                        .getSeparatedDigits()}`,
                );
                return resultArray;
            }

            const currentDataset = ctx.chart.data.datasets[ctx.datasetIndex];
            const currentValue = ctx.dataset.data[ctx.dataIndex];

            if (+currentValue === 0) {
                return null;
            }
            try {
                return `${currentDataset.config.assetSymbol[ctx.dataIndex]}: ${(Number(currentDataset.config.baseBalance[ctx.dataIndex]))
                    .floor(currentDataset.config.assetPrecision)
                    .noExponents()
                    .getSeparatedDigits()}; ${this.quotationAssetSymbol}: ${(+currentValue)
                    .toFixed(this.quotationAssetPrecision)
                    .getSeparatedDigits()}`;
            } catch (error) {
                // code crushed
            }
        },
        tooltipFooterCallback(ctx) {
            return `Total value in ${this.quotationAssetSymbol}: ${this.quotationAssetCharacter}${(ctx.reduce((accum, current) => accum + current.raw, 0))
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
        yAxeLabelCallback(value) {
            return `${this.quotationAssetCharacter}${value.toFixed(this.quotationAssetPrecision).getSeparatedDigits()}`;
        },

        isAssetFiltered(assetSymbol) {
            return this.assetsToShow.indexOf(assetSymbol) === -1;
        },
    },
    watch: {
        legendAssets(assets) {
            this.assetsToShow = assets;
        },
        range() {
            this.getData(this.range, this.module);
        },
        activeAccountId(value) {
            if (value && this.balancesLength > 0) {
                this.getData(this.range, this.module);
            }
        },
        balancesLength(value) {
            if (value > 0 && this.activeAccountId) {
                this.getData(this.range, this.module);
            }
        },
        portfolioType() {
            if (!this.module && (this.activeAccount && this.range)) {
                this.getData(this.range, this.module);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.h100 {
    height: 100%;
}
.mAuto {
    margin: auto;
}
</style>
