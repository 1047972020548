
import Vue from 'vue';
import { mapGetters } from 'vuex';

import AssetValue from 'UI/AssetValue.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

interface Props {
    amount?: number;
    network?: string;
    assetSymbol?: string;
    address?: string;
    isEmpty?: boolean;
    addressAlias?: string;
    isAssetFiat?: boolean;
}

interface Data {
    quotedAmount: number;
    calculatePrecision: typeof calculatePrecision;
}

interface Methods {
    quotateAmount: () => void;
}

interface Computed {
    shortAddress: string;
    quotationAssetSymbol: string;
    truncatedAmount: string;
    truncatedValue: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: { AssetValue },
    props: {
        amount: {
            type: Number,
            default: 0,
        },
        network: {
            type: String,
            default: '',
        },
        assetSymbol: {
            type: String,
            default: '',
        },
        address: {
            type: String,
            default: '',
        },
        isEmpty: {
            type: Boolean,
            default: false,
        },
        addressAlias: {
            type: String,
            default: '',
        },
        isAssetFiat: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            quotedAmount: 0,
            calculatePrecision,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        shortAddress() {
            return `${this.address?.substring(0, 4)}...${this.address?.substring(this.address?.length - 4, this.address?.length)}` ?? '...';
        },
        truncatedAmount() {
            if (this.isAssetFiat) {
                return this.amount?.toFixed(2).getSeparatedDigits() ?? '...';
            }
            return this.amount?.floor(8).getSeparatedDigits() ?? '...';
        },
        truncatedValue() {
            if (this.quotationAssetSymbol === 'EUR' || this.quotationAssetSymbol === 'USD') {
                return this.quotedAmount.toFixed(calculatePrecision(this.quotationAssetSymbol)).getSeparatedDigits();
            }
            return this.quotedAmount.floor(calculatePrecision(this.quotationAssetSymbol)).getSeparatedDigits();
        },
    },
    methods: {
        async quotateAmount() {
            if (!this.assetSymbol || !this.amount) {
                this.quotedAmount = 0;
                return;
            }

            const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
                quotationSymbol: this.quotationAssetSymbol,
                symbol: this.assetSymbol,
            }));

            this.quotedAmount = (this.amount * quotation.rate);
        },
    },
    watch: {
        amount() {
            this.quotateAmount();
        },
        assetSymbol() {
            this.quotateAmount();
        },
    },
});
